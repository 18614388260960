import { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/constants';
import { getRefreshToken, getToken } from '@/utils/localStorage';
import { AppConfig } from '@/config';

export function GridSample() {
  const params = useParams();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [reloadCount, setReloadCount] = useState(0);

  // @NOTE: - NUMBER LOAD - Maximum number of reloads to avoid infinite loops
  const maxReloads = 3;

  const handleReload = () => {
    if (reloadCount < maxReloads) {
      setReloadCount(reloadCount + 1);
      if (iframeRef.current) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    const iframeElement = iframeRef.current;
    const handleLoadError = () => {
      // @NOTE: - Wait 3 seconds before reloading
      alert('Error loading the map editor');
      setTimeout(handleReload, 1000);
    };
    iframeElement?.addEventListener('error', handleLoadError);
    return () => {
      iframeElement?.removeEventListener('error', handleLoadError);
    };
  }, [reloadCount]);

  const accessToken = getToken();
  const refreshToken = getRefreshToken();
  const baseUrl = AppConfig.coreEditorBaseUrl;

  const url = new URL(window.location.href);
  const autoPublish = url.searchParams.get("autoPublish") || null;

  return (
    <>
      <iframe
        ref={iframeRef}
        src={`${baseUrl}/${params?.venueId}?token=${accessToken}&refresh_token=${refreshToken}&time=${new Date().getTime()}&autoPublish=${autoPublish}`}
        style={{ border: 0, width: '100%', height: '100%' }}
        id="mapEditor"
        onLoad={() => setReloadCount(0)}
      ></iframe>
    </>
  );
}
